/* General Styles */
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: #333;
  }
  
  .who-we-are {
    background-color: #f8f9fa;
  }
  
  .section-title {
    font-weight: 700;
    color: #d9230f;
    position: relative;
    padding-bottom: 15px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .section-title:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background-color: #d9230f;
  }
  
  .section-subtitle {
    font-weight: 500;
    color: #6c757d;
    font-size: 1.2rem;
  }
  
  /* Volunteer CTA Section */
  .volunteer-cta {
    background:  url('../img/bg2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    text-align: center;
    padding: 80px 0;
  }
  
  .cta-title {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .cta-text {
    font-size: 1.3rem;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
  }
  
  .cta-button {
    background-color: #d9230f;
    border: none;
    padding: 15px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    font-size: 1.1rem;
    border-radius: 50px;
  }
  
  .cta-button:hover {
    background-color: #a91b0c;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Founder Section */
  .founder-section {
    background-color: white;
    padding: 80px 0;
  }
  
  .founder-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    background: white;
  }
  
  .founder-card .image-container {
    height: 450px;
    overflow: hidden;
  }
  
  .founder-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.5s ease;
  }
  
  .founder-card:hover .founder-img {
    transform: scale(1.05);
  }
  
  .founder-bio {
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    font-size: 1.1rem;
    line-height: 1.8;
  }
  
  /* Donation CTA Section */
  .donation-card {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  }
  
  .volunteer-cta-card {
    background: linear-gradient(135deg, #d9230f, #a91b0c);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Board Members Section */
  .board-section {
    background-color: #f8f9fa;
    padding: 80px 0;
  }
  
  .board-member-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    background-color: white;
    position: relative;
  }
  
  .board-member-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 0 0 rgba(217, 35, 15, 0);
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .board-member-card:hover::before {
    box-shadow: 0 0 20px rgba(217, 35, 15, 0.6);
  }
  
  .image-container {
    height: 300px;
    overflow: hidden;
  }
  
  .board-member-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .board-member-card:hover .board-member-img {
    transform: scale(1.1);
  }
  
  .board-member-card .card-body {
    padding: 20px;
  }
  
  .board-member-card .card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  /* Volunteer Section */
  .volunteer-section {
    background: white;
    padding: 80px 0;
  }
  
  .volunteer-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    height: 100%;
    background-color: white;
    position: relative;
  }
  
  .volunteer-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 0 0 rgba(217, 35, 15, 0);
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .volunteer-card:hover::before {
    box-shadow: 0 0 20px rgba(217, 35, 15, 0.6);
  }
  
  .volunteer-card .image-container {
    height: 300px;
    overflow: hidden;
  }
  
  .volunteer-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .volunteer-card:hover .volunteer-img {
    transform: scale(1.1);
  }
  
  .volunteer-text {
    font-size: 1.1rem;
    line-height: 1.8;
    padding: 20px;
  }
  
  .volunteer-button {
    background-color: #d9230f;
    border: none;
    padding: 15px 40px;
    font-weight: 600;
    transition: all 0.3s ease;
    font-size: 1.1rem;
    border-radius: 50px;
  }
  
  .volunteer-button:hover {
    background-color: #a91b0c;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1200px) {
    .section-title {
      font-size: 2.2rem;
    }
  }
  
  @media (max-width: 992px) {
    .founder-card .image-container {
      height: 350px;
    }
    
    .image-container {
      height: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .cta-title {
      font-size: 2.2rem;
    }
    
    .cta-text {
      font-size: 1.1rem;
    }
    
    .founder-card .image-container {
      height: 300px;
    }
    
    .volunteer-card .image-container {
      height: 250px;
    }
  }
  
  @media (max-width: 576px) {
    .section-title {
      font-size: 1.8rem;
    }
    
    .volunteer-cta {
      padding: 60px 0;
    }
    
    .cta-title {
      font-size: 1.8rem;
    }
    
    .founder-card .image-container {
      height: 250px;
    }
    
    .image-container {
      height: 200px;
    }
    
    .volunteer-text {
      font-size: 1rem;
    }
  }
  .org{
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: flex-end;  /* Aligns the content vertically at the bottom */
    height: 100%; /* Ensures the content takes the full height of the parent */
    text-align: center; /* Centers the text inside the content */
    padding-bottom: 30px; /* Adds some padding to move it downward */
  }